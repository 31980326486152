import api from "./api";

const MEMBERS_URL = "/members";

// get member managements
export const getMembers = async (filter) => {
  return api
    .get(MEMBERS_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const saveMember = async (data) => {
  return api.post(MEMBERS_URL, data).then((res) => res.data);
};

export const getEditMember = async (username) => {
  return api.get(`${MEMBERS_URL}/${username}`).then((res) => res.data);
};

export const updateMember = async (username, data) => {
  return api.put(`${MEMBERS_URL}/${username}`, data).then((res) => res.data);
};
