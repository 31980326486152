import { memo, useCallback, useState } from "react";
import { Layout } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import Routes from "pages/routes";
import { getAccessToken } from "../services";

const { Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const accToken = getAccessToken();

  const toggleMenu = useCallback(() => {
    setCollapsed((v) => !v);
  }, [setCollapsed]);

  return (
    <Layout className="admin-app-container">
      <Layout className="layout">
        {accToken && <Header collapsed={collapsed} toggleMenu={toggleMenu} />}
        <Content className="main-content">
          <Routes />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default memo(MainLayout);
