import { memo } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import LoginPage from "./LoginPage";
import { getAccessToken } from "../services";

// import components page
import DashboardRoutes from "./Dashboards/DashboardRouters";
import PlantRoutes from "./Stations/PlantRoutes";

const privateRoute = () => (
  <Switch>
    <Route path={`/dashboard`} component={DashboardRoutes} />
    <Route path={`/stations`} component={PlantRoutes} />
    <Route path={`/`} component={DashboardRoutes} />
    <Route component={NotFound} />
  </Switch>
);

const publicRoute = () => (
  <Switch>
    <Route path="/signin" component={LoginPage} />
    <Route component={LoginPage} />
  </Switch>
);

const Routes = () => {
  const token = getAccessToken();
  return token ? privateRoute() : publicRoute();
};

export default memo(Routes);
