import { memo } from "react";
import { Select, Space } from "antd";
import styled from "styled-components";
import thFlag from "assets/nation-flag/th.png";
import enFlag from "assets/nation-flag/en.png";
// import zhFlag from "assets/nation-flag/zh.png";

const { Option } = Select;

const LangSwitch = (props) => {
  return (
    <Select {...props}>
      <Option value="en" key={0}>
        <Space>
          <NationFlag src={enFlag} alt="english language" />
          <span className="">EN</span>
        </Space>
      </Option>
      <Option value="th" key={1}>
        <Space>
          <NationFlag src={thFlag} alt="thai language" />
          <span className="">TH</span>
        </Space>
      </Option>

      {/* <Option value="zh" key={2}>
        <Space>
          <NationFlag src={zhFlag} alt="chinese language" />
          <span className="">ZH</span>
        </Space>
      </Option> */}
    </Select>
  );
};

export default memo(LangSwitch);

const NationFlag = styled.img`
  width: 23.33px;
  height: 18px;
  border-radius: 3px;
`;
