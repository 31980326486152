import api from "./api";

const GEO_URL = "/geos";

// get accounts managements
export const getGPSs = async (filter) => {
  return api
    .get(GEO_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const saveGPS = async (data) => {
  return api.post(GEO_URL, data).then((res) => res.data);
};

export const getEditGPS = async (id) => {
  return api.get(`${GEO_URL}/${id}`).then((res) => res.data);
};

export const updateGPS = async (id, data) => {
  return api.put(`${GEO_URL}/${id}`, data).then((res) => res.data);
};
