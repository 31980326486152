import { memo } from "react";
import { Card, Row, Col, Space, Image, Button } from "antd";

import { ChevronRight } from "react-feather";

import StationImg from "assets/mockups/station.png";

function CardLists() {
  return (
    <div>
      <Card className="list-data mb-3">
        <Row gutter={15}>
          <Col className="pe-3 text-center" xs={3}>
            <Image
              preview={false}
              style={{ width: "32px", height: "32px" }}
              src={StationImg}
            ></Image>
          </Col>
          <Col xs={18}>
            <div className="card-info">
              <h1 className="title">Plant mockup name</h1>
              <p className="description">About Plant</p>
            </div>
          </Col>
          <Col xs={3}>
            <div className="text-end ">
              <Space align="center">
                <Button
                  type="dashed"
                  shape="circle"
                  icon={
                    <ChevronRight
                      className="ms-1"
                      style={{ marginTop: "-2px" }}
                      size={18}
                    />
                  }
                />
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default memo(CardLists);
