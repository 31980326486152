import { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Space, Dropdown, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useToggleIndex } from "hooks";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import LangSwitch from "components/LangSwitch";
import AuthUserSelector from "redux/auth-user/selector";
import { changeLocale } from "redux/auth-user/reducer";
import { logout, getUserInfo } from "../services";
import LogoWeb from "../assets/enserv-logo.png";
import SmallLogoWeb from "../assets/enserv-small-logo.png";
import { Menu as MenuIcon, X as CloseX } from "react-feather";

import MenuLists from "./Menus";
import MenuMobile from "./MenuMobile";

const { Header } = Layout;

const AdminHeader = ({ collapsed }) => {
  const dispatch = useDispatch();
  const userLocale = useSelector(AuthUserSelector.locale);
  const [Arrow, toggleArrow] = useToggleIndex(
    [CaretDownOutlined, CaretUpOutlined],
    0
  );

  const [menuactive, setMenuActive] = useState(false);

  const userInfo = getUserInfo();

  const menu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item>
          <Space align="baseline" onClick={logout}>
            <PoweroffOutlined className="icon-up" />
            <span className="ms-1">Logout</span>
          </Space>
        </Menu.Item>
      </Menu>
    );
  }, []);

  const onLangChange = (locale) => dispatch(changeLocale(locale));

  return (
    <Header
      className="d-flex ant-layout-header header"
      style={{ minWidth: "100%", position: "fixed", zIndex: 100 }}
    >
      <div className="icon-toggle-menu ps-3">
        <Button
          type="primary-outline"
          icon={menuactive ? <CloseX /> : <MenuIcon />}
          onClick={() => setMenuActive(!menuactive)}
        ></Button>
      </div>
      <div className="app-logo ms-4">
        {!collapsed && (
          <Link to="/dashboard">
            <span>
              <img src={LogoWeb} style={{ height: "24px" }} alt="logo" />
            </span>
          </Link>
        )}
        {collapsed && (
          <Link to="/dashboard">
            <span>
              <img src={SmallLogoWeb} style={{ height: "30px" }} alt="logo" />
            </span>
          </Link>
        )}
      </div>
      {menuactive && (
        <MenuMobile>
          <div className="lang-mobile">
            <LangSwitch value={userLocale} onChange={onLangChange} />
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              onVisibleChange={toggleArrow}
              trigger={["click"]}
            >
              <div className="d-flex align-items-center pointer my-3 mx-2">
                <div className="avatar-cover bg-user-icon mr-2"></div>
                <div className="user-name mr-2">
                  <span className="mr-2" style={{ fontSize: "14px" }}>
                    {userInfo?.displayName || userInfo?.username}
                  </span>
                </div>
                <Arrow
                  className="arrow-icon ms-2 mt-1"
                  style={{ fontSize: "12px" }}
                />
              </div>
            </Dropdown>
          </div>
        </MenuMobile>
      )}

      <MenuLists />
      <Space size="large" style={{ right: "15px", position: "absolute" }}>
        <LangSwitch
          value={userLocale}
          onChange={onLangChange}
          className="desktop-menus"
        />
        <Dropdown
          overlay={menu}
          placement="bottomCenter"
          onVisibleChange={toggleArrow}
          trigger={["click"]}
        >
          <div className="d-flex align-items-center pointer mr-4">
            <div className="avatar-cover bg-user-icon mr-2"></div>
            <div className="user-name mr-2">
              <span className="mr-2" style={{ fontSize: "14px" }}>
                {userInfo?.displayName || userInfo?.username}
              </span>
            </div>
            <Arrow
              className="arrow-icon ms-2 mt-1"
              style={{ fontSize: "12px" }}
            />
          </div>
        </Dropdown>
      </Space>
    </Header>
  );
};

export default memo(AdminHeader);
