import api from "./api";

const ENERGYTREND_URL = "/energys";

// get accounts managements
export const getEnergyDays = async (plantId, filter) => {
  return api
    .post(`${ENERGYTREND_URL}/${plantId}`, filter)
    .then((res) => res.data);
};
