import { memo } from "react";
import { Card, Row, Col, Space, Image } from "antd";
import IntlMessages from "components/IntlMessages";

const CardDashboard = ({ icon, title, value, color }) => {
  return (
    <Card
      style={{ minHeight: "100%" }}
      className="align-items-center text-center"
    >
      <Space align="center">
        <div className={`icon-cards ${color}`}>
          <Image src={icon} alt="PvGenerator" preview={false} />
        </div>
      </Space>
      <h4 className="mb-0 text-primary">{<IntlMessages id={title} />}</h4>
      <Space align="center">
        <Row className="mt-3">
          <Col xs={24}>
            <h2 className="text-right pe-2 text-bigger">{value} </h2>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default memo(CardDashboard);
