import { memo, useState } from "react";
import { Breadcrumb, Spin, Row, Col } from "antd";
import IntlMessages from "components/IntlMessages";
import { Card, HeaderTitle } from "components/styled-components";

import { useMount } from "hooks";

import { getBanners } from "services";

import CardDashboard from "./ComponentDashboards/CardDashboard";
import BannerHero from "./ComponentDashboards/BannerHero";
import CardLists from "./ComponentDashboards/CardLists";
import LineChartEnergy from "./ComponentDashboards/LineChartEnergy";

// import images
import BahtIcon from "assets/mockups/baht.png";
import ChargedIcon from "assets/mockups/charged.png";
import DischargedIcon from "assets/mockups/discharged.png";
import CarbonIcon from "assets/mockups/co2e.png";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  useMount(() => void fetchDataDefault());

  const fetchDataDefault = async () => {
    setLoading(true);
    const { data } = await getBanners();

    if (data) {
      setBanners(data.banners);
      setLoading(false);
    }
  };

  return (
    <section className="bg-main">
      <Card>
        <HeaderTitle>
          <div>
            <h1>
              <IntlMessages id={`dashboard`} />
            </h1>
            <Breadcrumb className="mb-3">
              <Breadcrumb.Item>
                <IntlMessages id={`dashboard`} />
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </HeaderTitle>
        <Spin spinning={loading}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="mb-3 space-reponsive"
                >
                  <BannerHero initialData={banners} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="mb-3 space-reponsive"
                >
                  <div className="mb-3">
                    <h4 className="mb-3 text-primary">
                      <IntlMessages id={`energy_trend`} />
                    </h4>
                    <LineChartEnergy />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row gutter={16}>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mb-3 space-reponsive"
                >
                  <CardDashboard
                    icon={BahtIcon}
                    title={`energy_price`}
                    value={0}
                    color={`yellow`}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mb-3 space-reponsive"
                >
                  <CardDashboard
                    icon={DischargedIcon}
                    title={`energy_discharged`}
                    value={0}
                    color={`red`}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mb-3 space-reponsive"
                >
                  <CardDashboard
                    icon={ChargedIcon}
                    title={`energy_charged`}
                    value={0}
                    color={`blue`}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mb-3 space-reponsive"
                >
                  <CardDashboard
                    icon={CarbonIcon}
                    title={`co2_reduce`}
                    value={0}
                    color={`green`}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <CardLists />
                  <CardLists />
                  <CardLists />
                  <CardLists />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Card>
    </section>
  );
};

export default memo(Dashboard);
