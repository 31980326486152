import api from "./api";

const VEHICLES_URL = "/vehicles";

export const getDetailVehicle = async (vehicleId) => {
  return api.get(`${VEHICLES_URL}/${vehicleId}`).then((res) => res.data);
};

export const getDetailMutipleVehicle = async (data) => {
  return api.post(`${VEHICLES_URL}`, data).then((res) => res.data);
};

export const getDataPlotBatteries = async (data) => {
  return api
    .post(`${VEHICLES_URL}/plot-batteries`, data)
    .then((res) => res.data);
};
