import api from "./api";

const ACCOUNT_URL = "/accounts";

// get accounts managements
export const getAccounts = async (filter) => {
  return api
    .get(ACCOUNT_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const saveAccount = async (data) => {
  return api.post(ACCOUNT_URL, data).then((res) => res.data);
};

export const getEditAccount = async (username) => {
  return api.get(`${ACCOUNT_URL}/${username}`).then((res) => res.data);
};

export const updateAccount = async (username, data) => {
  return api.put(`${ACCOUNT_URL}/${username}`, data).then((res) => res.data);
};
