import api from "./api";

const PLANT_URL = "/plants";
const PLANT_CONTROL_URL = "/plant-controls";

// get plants managements
export const getPlants = async (filter) => {
  return api
    .get(PLANT_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getDetailPlant = async (plantId) => {
  return api.get(`${PLANT_URL}/${plantId}`).then((res) => res.data);
};

export const getPlantEnergys = async (plantId, filter) => {
  return api
    .get(`${PLANT_URL}/energy-historys/${plantId}`, { params: filter })
    .then((res) => res.data);
};

export const getPlantControlMqtt = async (plantId, events) => {
  return api
    .get(`${PLANT_CONTROL_URL}/${plantId}`, {
      params: {
        events: events.events,
        setUp: events.setUp,
      },
    })
    .then((res) => res.data);
};
