import { memo, useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Layers, FileText, BarChart2 } from "react-feather";
import { DashboardOutlined } from "@ant-design/icons";
import IntlMessages from "components/IntlMessages";

const defaultOpenKeys = ["sub1"];

const AdminMenuMobiles = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = useState([location.pathname]);

  useEffect(() => setCurrent([location.pathname]), [location.pathname]);

  function onMenuSelected(e) {
    const menu = e.key;
    if (location.pathname !== menu) {
      setCurrent(e.key);
      history.push(menu);
    }
  }

  return (
    <div className="mobile-menus">
      <Menu
        className="mx-3"
        onClick={onMenuSelected}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={current}
        mode="vertical"
        theme="dark"
        style={{ width: "300px" }}
      >
        {/* MAIN */}
        <Menu.Item
          key="/dashboard"
          icon={<DashboardOutlined size={16} className="transform-bug" />}
        >
          <IntlMessages id="dashboard" />
        </Menu.Item>
        <Menu.Item
          key="/stations"
          icon={<Layers size={16} className="transform-bug" />}
        >
          <IntlMessages id="plants" />
        </Menu.Item>
        <Menu.Item
          key="/energys"
          icon={<BarChart2 size={16} className="transform-bug" />}
        >
          <IntlMessages id="energys" />
        </Menu.Item>
        <Menu.Item
          key="/logs"
          icon={<FileText size={16} className="transform-bug" />}
        >
          <IntlMessages id="logs" />
        </Menu.Item>
      </Menu>
      {props.children}
    </div>
  );
};

export default memo(AdminMenuMobiles);
