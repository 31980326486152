import { memo } from "react";
import { Carousel, Image } from "antd";

const BannerHero = ({ initialData }) => {
  const contentStyle = {
    color: "#fff",
    textAlign: "center",
    background: "#d5faf069",
    width: "100%",
    borderRadius: "5px",
    padding: "2px",
  };

  return (
    <section>
      <Carousel autoplay className="mb-3">
        {initialData?.map((bn, k) => {
          return (
            <div key={k}>
              <Image
                style={contentStyle}
                preview={true}
                src={bn?.imagePath}
              ></Image>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default memo(BannerHero);
